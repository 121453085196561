// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-guest-template-js": () => import("../src/components/guestTemplate.js" /* webpackChunkName: "component---src-components-guest-template-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alle-gaeste-js": () => import("../src/pages/alle-gaeste.js" /* webpackChunkName: "component---src-pages-alle-gaeste-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nachricht-js": () => import("../src/pages/nachricht.js" /* webpackChunkName: "component---src-pages-nachricht-js" */)
}

